import { default as _downloadsYG0EChzJOvMeta } from "D:/W-S Projekte/WS_intern/ws-styleguide/pages/_downloads.vue?macro=true";
import { default as _91slug_93ykF5TxJCBEMeta } from "D:/W-S Projekte/WS_intern/ws-styleguide/pages/[secondlevel]/[slug].vue?macro=true";
import { default as indexaqdrUTl9n4Meta } from "D:/W-S Projekte/WS_intern/ws-styleguide/pages/[secondlevel]/index.vue?macro=true";
import { default as indexLea1eMJJffMeta } from "D:/W-S Projekte/WS_intern/ws-styleguide/pages/index.vue?macro=true";
import { default as data_45privacyUIIbrxvcRtMeta } from "D:/W-S Projekte/WS_intern/ws-styleguide/pages/services/data-privacy.vue?macro=true";
import { default as imprintaySQgHtnduMeta } from "D:/W-S Projekte/WS_intern/ws-styleguide/pages/services/imprint.vue?macro=true";
import { default as terms_45and_45servicesZdtDSCaq73Meta } from "D:/W-S Projekte/WS_intern/ws-styleguide/pages/services/terms-and-services.vue?macro=true";
export default [
  {
    name: "_downloads",
    path: "/_downloads",
    component: () => import("D:/W-S Projekte/WS_intern/ws-styleguide/pages/_downloads.vue").then(m => m.default || m)
  },
  {
    name: "secondlevel-slug",
    path: "/:secondlevel()/:slug()",
    component: () => import("D:/W-S Projekte/WS_intern/ws-styleguide/pages/[secondlevel]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "secondlevel",
    path: "/:secondlevel()",
    component: () => import("D:/W-S Projekte/WS_intern/ws-styleguide/pages/[secondlevel]/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("D:/W-S Projekte/WS_intern/ws-styleguide/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "services-data-privacy",
    path: "/services/data-privacy",
    component: () => import("D:/W-S Projekte/WS_intern/ws-styleguide/pages/services/data-privacy.vue").then(m => m.default || m)
  },
  {
    name: "services-imprint",
    path: "/services/imprint",
    component: () => import("D:/W-S Projekte/WS_intern/ws-styleguide/pages/services/imprint.vue").then(m => m.default || m)
  },
  {
    name: "services-terms-and-services",
    path: "/services/terms-and-services",
    component: () => import("D:/W-S Projekte/WS_intern/ws-styleguide/pages/services/terms-and-services.vue").then(m => m.default || m)
  }
]